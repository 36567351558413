/* tslint:disable */
/* eslint-disable */
/**
 * MAC API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateAppPlatformRequestDto
 */
export interface UpdateAppPlatformRequestDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateAppPlatformRequestDto
     */
    emailVerifyStatus: UpdateAppPlatformRequestDtoEmailVerifyStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateAppPlatformRequestDto
     */
    comments?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAppPlatformRequestDto
     */
    platformId: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAppPlatformRequestDto
     */
    requestType: UpdateAppPlatformRequestDtoRequestTypeEnum;
}


/**
 * @export
 */
export const UpdateAppPlatformRequestDtoEmailVerifyStatusEnum = {
    Pending: 'PENDING',
    Approved: 'APPROVED',
    Rejected: 'REJECTED',
    Fyi: 'FYI'
} as const;
export type UpdateAppPlatformRequestDtoEmailVerifyStatusEnum = typeof UpdateAppPlatformRequestDtoEmailVerifyStatusEnum[keyof typeof UpdateAppPlatformRequestDtoEmailVerifyStatusEnum];

/**
 * @export
 */
export const UpdateAppPlatformRequestDtoRequestTypeEnum = {
    AppVerify: 'APP_VERIFY',
    PlatformUpdate: 'PLATFORM_UPDATE',
    OwnerRequest: 'OWNER_REQUEST',
    PlatformChange: 'PLATFORM_CHANGE',
    NotifyPlatforms: 'NOTIFY_PLATFORMS',
    ApplicationDataIssue: 'APPLICATION_DATA_ISSUE',
    OwnerRemoval: 'OWNER_REMOVAL'
} as const;
export type UpdateAppPlatformRequestDtoRequestTypeEnum = typeof UpdateAppPlatformRequestDtoRequestTypeEnum[keyof typeof UpdateAppPlatformRequestDtoRequestTypeEnum];


/**
 * Check if a given object implements the UpdateAppPlatformRequestDto interface.
 */
export function instanceOfUpdateAppPlatformRequestDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "emailVerifyStatus" in value;
    isInstance = isInstance && "platformId" in value;
    isInstance = isInstance && "requestType" in value;

    return isInstance;
}

export function UpdateAppPlatformRequestDtoFromJSON(json: any): UpdateAppPlatformRequestDto {
    return UpdateAppPlatformRequestDtoFromJSONTyped(json, false);
}

export function UpdateAppPlatformRequestDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateAppPlatformRequestDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'emailVerifyStatus': json['emailVerifyStatus'],
        'comments': !exists(json, 'comments') ? undefined : json['comments'],
        'platformId': json['platformId'],
        'requestType': json['requestType'],
    };
}

export function UpdateAppPlatformRequestDtoToJSON(value?: UpdateAppPlatformRequestDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'emailVerifyStatus': value.emailVerifyStatus,
        'comments': value.comments,
        'platformId': value.platformId,
        'requestType': value.requestType,
    };
}

