/* tslint:disable */
/* eslint-disable */
/**
 * MAC API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ResponseDto } from './ResponseDto';
import {
    ResponseDtoFromJSON,
    ResponseDtoFromJSONTyped,
    ResponseDtoToJSON,
} from './ResponseDto';

/**
 * 
 * @export
 * @interface JobTriggerResponseDto
 */
export interface JobTriggerResponseDto {
    /**
     * 
     * @type {Array<ResponseDto>}
     * @memberof JobTriggerResponseDto
     */
    responses?: Array<ResponseDto>;
}

/**
 * Check if a given object implements the JobTriggerResponseDto interface.
 */
export function instanceOfJobTriggerResponseDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function JobTriggerResponseDtoFromJSON(json: any): JobTriggerResponseDto {
    return JobTriggerResponseDtoFromJSONTyped(json, false);
}

export function JobTriggerResponseDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): JobTriggerResponseDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'responses': !exists(json, 'responses') ? undefined : ((json['responses'] as Array<any>).map(ResponseDtoFromJSON)),
    };
}

export function JobTriggerResponseDtoToJSON(value?: JobTriggerResponseDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'responses': value.responses === undefined ? undefined : ((value.responses as Array<any>).map(ResponseDtoToJSON)),
    };
}

