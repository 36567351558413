/* tslint:disable */
/* eslint-disable */
/**
 * MAC API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { OwnerDto } from './OwnerDto';
import {
    OwnerDtoFromJSON,
    OwnerDtoFromJSONTyped,
    OwnerDtoToJSON,
} from './OwnerDto';
import type { PortfolioMetaResponseDto } from './PortfolioMetaResponseDto';
import {
    PortfolioMetaResponseDtoFromJSON,
    PortfolioMetaResponseDtoFromJSONTyped,
    PortfolioMetaResponseDtoToJSON,
} from './PortfolioMetaResponseDto';

/**
 * 
 * @export
 * @interface PlatformResponseDto
 */
export interface PlatformResponseDto {
    /**
     * 
     * @type {string}
     * @memberof PlatformResponseDto
     */
    platformId?: string;
    /**
     * 
     * @type {string}
     * @memberof PlatformResponseDto
     */
    platformName?: string;
    /**
     * 
     * @type {string}
     * @memberof PlatformResponseDto
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof PlatformResponseDto
     */
    status?: PlatformResponseDtoStatusEnum;
    /**
     * 
     * @type {Set<OwnerDto>}
     * @memberof PlatformResponseDto
     */
    owners?: Set<OwnerDto>;
    /**
     * 
     * @type {string}
     * @memberof PlatformResponseDto
     */
    platformType?: string;
    /**
     * 
     * @type {PortfolioMetaResponseDto}
     * @memberof PlatformResponseDto
     */
    portfolio?: PortfolioMetaResponseDto;
    /**
     * 
     * @type {string}
     * @memberof PlatformResponseDto
     */
    createdBy?: string;
    /**
     * 
     * @type {Date}
     * @memberof PlatformResponseDto
     */
    createdDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof PlatformResponseDto
     */
    modifiedBy?: string;
    /**
     * 
     * @type {Date}
     * @memberof PlatformResponseDto
     */
    modifiedDate?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof PlatformResponseDto
     */
    nameVerified?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PlatformResponseDto
     */
    canEdit?: boolean;
}


/**
 * @export
 */
export const PlatformResponseDtoStatusEnum = {
    Active: 'Active',
    Inactive: 'Inactive',
    Rejected: 'Rejected',
    Deleted: 'Deleted'
} as const;
export type PlatformResponseDtoStatusEnum = typeof PlatformResponseDtoStatusEnum[keyof typeof PlatformResponseDtoStatusEnum];


/**
 * Check if a given object implements the PlatformResponseDto interface.
 */
export function instanceOfPlatformResponseDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PlatformResponseDtoFromJSON(json: any): PlatformResponseDto {
    return PlatformResponseDtoFromJSONTyped(json, false);
}

export function PlatformResponseDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PlatformResponseDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'platformId': !exists(json, 'platformId') ? undefined : json['platformId'],
        'platformName': !exists(json, 'platformName') ? undefined : json['platformName'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'owners': !exists(json, 'owners') ? undefined : (new Set((json['owners'] as Array<any>).map(OwnerDtoFromJSON))),
        'platformType': !exists(json, 'platformType') ? undefined : json['platformType'],
        'portfolio': !exists(json, 'portfolio') ? undefined : PortfolioMetaResponseDtoFromJSON(json['portfolio']),
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'createdDate': !exists(json, 'createdDate') ? undefined : (new Date(json['createdDate'])),
        'modifiedBy': !exists(json, 'modifiedBy') ? undefined : json['modifiedBy'],
        'modifiedDate': !exists(json, 'modifiedDate') ? undefined : (new Date(json['modifiedDate'])),
        'nameVerified': !exists(json, 'nameVerified') ? undefined : json['nameVerified'],
        'canEdit': !exists(json, 'canEdit') ? undefined : json['canEdit'],
    };
}

export function PlatformResponseDtoToJSON(value?: PlatformResponseDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'platformId': value.platformId,
        'platformName': value.platformName,
        'description': value.description,
        'status': value.status,
        'owners': value.owners === undefined ? undefined : (Array.from(value.owners as Set<any>).map(OwnerDtoToJSON)),
        'platformType': value.platformType,
        'portfolio': PortfolioMetaResponseDtoToJSON(value.portfolio),
        'createdBy': value.createdBy,
        'createdDate': value.createdDate === undefined ? undefined : (value.createdDate.toISOString()),
        'modifiedBy': value.modifiedBy,
        'modifiedDate': value.modifiedDate === undefined ? undefined : (value.modifiedDate.toISOString()),
        'nameVerified': value.nameVerified,
        'canEdit': value.canEdit,
    };
}

