/* tslint:disable */
/* eslint-disable */
/**
 * MAC API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface NotificationRequestDto
 */
export interface NotificationRequestDto {
    /**
     * 
     * @type {string}
     * @memberof NotificationRequestDto
     */
    email: string;
    /**
     * 
     * @type {Set<string>}
     * @memberof NotificationRequestDto
     */
    portfolioIds?: Set<string>;
    /**
     * 
     * @type {Set<string>}
     * @memberof NotificationRequestDto
     */
    platformIds?: Set<string>;
    /**
     * 
     * @type {Set<string>}
     * @memberof NotificationRequestDto
     */
    applicationIds?: Set<string>;
}

/**
 * Check if a given object implements the NotificationRequestDto interface.
 */
export function instanceOfNotificationRequestDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "email" in value;

    return isInstance;
}

export function NotificationRequestDtoFromJSON(json: any): NotificationRequestDto {
    return NotificationRequestDtoFromJSONTyped(json, false);
}

export function NotificationRequestDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): NotificationRequestDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'email': json['email'],
        'portfolioIds': !exists(json, 'portfolioIds') ? undefined : json['portfolioIds'],
        'platformIds': !exists(json, 'platformIds') ? undefined : json['platformIds'],
        'applicationIds': !exists(json, 'applicationIds') ? undefined : json['applicationIds'],
    };
}

export function NotificationRequestDtoToJSON(value?: NotificationRequestDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'email': value.email,
        'portfolioIds': value.portfolioIds === undefined ? undefined : Array.from(value.portfolioIds as Set<any>),
        'platformIds': value.platformIds === undefined ? undefined : Array.from(value.platformIds as Set<any>),
        'applicationIds': value.applicationIds === undefined ? undefined : Array.from(value.applicationIds as Set<any>),
    };
}

