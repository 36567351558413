/* tslint:disable */
/* eslint-disable */
/**
 * MAC API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DataDto } from './DataDto';
import {
    DataDtoFromJSON,
    DataDtoFromJSONTyped,
    DataDtoToJSON,
} from './DataDto';

/**
 * 
 * @export
 * @interface MvcUpdateResponseDto
 */
export interface MvcUpdateResponseDto {
    /**
     * 
     * @type {number}
     * @memberof MvcUpdateResponseDto
     */
    totalInputReceived?: number;
    /**
     * 
     * @type {number}
     * @memberof MvcUpdateResponseDto
     */
    totalProcessed?: number;
    /**
     * 
     * @type {number}
     * @memberof MvcUpdateResponseDto
     */
    totalFailed?: number;
    /**
     * 
     * @type {Array<DataDto>}
     * @memberof MvcUpdateResponseDto
     */
    failedData?: Array<DataDto>;
}

/**
 * Check if a given object implements the MvcUpdateResponseDto interface.
 */
export function instanceOfMvcUpdateResponseDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MvcUpdateResponseDtoFromJSON(json: any): MvcUpdateResponseDto {
    return MvcUpdateResponseDtoFromJSONTyped(json, false);
}

export function MvcUpdateResponseDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): MvcUpdateResponseDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'totalInputReceived': !exists(json, 'totalInputReceived') ? undefined : json['totalInputReceived'],
        'totalProcessed': !exists(json, 'totalProcessed') ? undefined : json['totalProcessed'],
        'totalFailed': !exists(json, 'totalFailed') ? undefined : json['totalFailed'],
        'failedData': !exists(json, 'failedData') ? undefined : ((json['failedData'] as Array<any>).map(DataDtoFromJSON)),
    };
}

export function MvcUpdateResponseDtoToJSON(value?: MvcUpdateResponseDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'totalInputReceived': value.totalInputReceived,
        'totalProcessed': value.totalProcessed,
        'totalFailed': value.totalFailed,
        'failedData': value.failedData === undefined ? undefined : ((value.failedData as Array<any>).map(DataDtoToJSON)),
    };
}

