/* tslint:disable */
/* eslint-disable */
/**
 * MAC API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ContentDto } from './ContentDto';
import {
    ContentDtoFromJSON,
    ContentDtoFromJSONTyped,
    ContentDtoToJSON,
} from './ContentDto';

/**
 * 
 * @export
 * @interface NotificationResponseDto
 */
export interface NotificationResponseDto {
    /**
     * 
     * @type {Array<ContentDto>}
     * @memberof NotificationResponseDto
     */
    applications?: Array<ContentDto>;
    /**
     * 
     * @type {Array<ContentDto>}
     * @memberof NotificationResponseDto
     */
    platforms?: Array<ContentDto>;
    /**
     * 
     * @type {Array<ContentDto>}
     * @memberof NotificationResponseDto
     */
    portfolios?: Array<ContentDto>;
}

/**
 * Check if a given object implements the NotificationResponseDto interface.
 */
export function instanceOfNotificationResponseDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function NotificationResponseDtoFromJSON(json: any): NotificationResponseDto {
    return NotificationResponseDtoFromJSONTyped(json, false);
}

export function NotificationResponseDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): NotificationResponseDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'applications': !exists(json, 'applications') ? undefined : ((json['applications'] as Array<any>).map(ContentDtoFromJSON)),
        'platforms': !exists(json, 'platforms') ? undefined : ((json['platforms'] as Array<any>).map(ContentDtoFromJSON)),
        'portfolios': !exists(json, 'portfolios') ? undefined : ((json['portfolios'] as Array<any>).map(ContentDtoFromJSON)),
    };
}

export function NotificationResponseDtoToJSON(value?: NotificationResponseDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'applications': value.applications === undefined ? undefined : ((value.applications as Array<any>).map(ContentDtoToJSON)),
        'platforms': value.platforms === undefined ? undefined : ((value.platforms as Array<any>).map(ContentDtoToJSON)),
        'portfolios': value.portfolios === undefined ? undefined : ((value.portfolios as Array<any>).map(ContentDtoToJSON)),
    };
}

